<template>
  <div class="lg:text-xl contact-us home relative text-base">    
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeader />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner pt-12 text-gray-800 bg-center">
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Contact Us</h2>
        <p class="lg:text-base text-sm">Home > <span class="text-sitePurple font-bold">Contact Us</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl pt-12 pb-8 mx-auto">
        <p class="lg:mb-16 lg:px-0 px-4 mb-8">Dr Marco believes in putting patients first, and has dedicated himself to
          providing all-encompassing care to ensure a positive patient experience. With over 35 years of experience in
          the field, Dr Marco is always ready to share his knowledge to help patients make informed decisions for a
          smooth recovery.</p>
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="mb-4 border-2 border-white" src="images/image-doctor.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <p class="lg:text-xl mb-2 text-lg font-bold">Dr Marco Faria Correa</p>
              <p class="mb-8">
                Physician’s Dip. (Rio Grande do Sul), Post Graduate Cert. (Plastic Surgery, Brazil),
                Specialist Cert. (Plastic Surgery, Brazil), TMSBCP (Plastic Surgery, Brazil),
                Accredited Specialist Plastic Surgeon (Ministry of Health, Singapore)
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteForm />
    <SiteFooter />
  </div>
</template>

<script>
  import SiteHeader from '@/components/SiteHeader.vue'
  import SiteForm from '@/components/SiteForm.vue'
  import SiteFooter from '@/components/SiteFooter.vue'
  export default {
    components: {
      SiteHeader,
      SiteForm,
      SiteFooter
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
</style>